import { pokemonApi } from '../../RTKService';
import { RequestOptionsType } from '../../../request';
import { WSInstance } from '../../../instance';

import {
  getEmployersURL,
  getMailingURL,
  getRegionCodesURL,
} from '../../../getURLHelpers/getURLHelpers';
import { TRegionCodesItem } from '../../types/mailing-types/mailing.types';
import {
  CreateMailingRequest,
  Employer,
  EmployerRequest,
  PatchedCreateMailingRequest,
  PatchedHhEmployerUpdateRequest,
  RetrieveMailing,
  SearchLinkRequest,
} from '../../../../Entities';
import { createEntityAdapter, EntityState } from '@reduxjs/toolkit';

export const mailingApi = pokemonApi.injectEndpoints({
  endpoints: (builder) => ({
    startMailing: builder.mutation<
      'Task was started',
      { id: number; customOptions?: RequestOptionsType }
    >({
      query: ({ id, customOptions }) => ({
        url: getMailingURL(`/${id}/start/`),
        method: 'post',
        customOptions,
      }),
      invalidatesTags: ['startTask'],
    }),
    stopMailing: builder.mutation<
      'Task was stopped',
      { id: number; customOptions?: RequestOptionsType }
    >({
      query: ({ id, customOptions }) => ({
        url: getMailingURL(`/${id}/stop/`),
        method: 'post',
        customOptions,
      }),
      invalidatesTags: ['stopTask'],
    }),

    getMailingById: builder.query<
      RetrieveMailing & { restricted_employerId: number[] },
      { id: number; customOptions?: RequestOptionsType }
    >({
      query: ({ id, customOptions }) => ({
        url: getMailingURL(`/${id}/`),
        customOptions,
      }),
      transformResponse: (response: RetrieveMailing) => {
        return {
          restricted_employerId: response.restricted_employers.map(
            (employer) => employer.id,
          ),
          ...response,
        };
      },
      async onCacheEntryAdded(
        { id },
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved },
      ) {
        // create a websocket connection when the cache subscription starts
        let ws;
        try {
          ws = new WSInstance(getMailingURL(`/${id}/`));
          // wait for the initial query to resolve before proceeding
          await cacheDataLoaded;

          // when data is received from the socket connection to the server,
          // if it is a message and for the appropriate channel,
          // update our query result with the received message
          const listener = (event: MessageEvent) => {
            updateCachedData(() => JSON.parse(event.data));
          };

          ws.addEventListener('message', listener);
        } catch (error) {
          console.error(error);
          // no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded`,
          // in which case `cacheDataLoaded` will throw
        }
        // cacheEntryRemoved will resolve when the cache subscription is no longer active
        await cacheEntryRemoved;
        // perform cleanup steps once the `cacheEntryRemoved` promise resolves
        ws && ws.close();
      },
    }),

    getMailingList: builder.query<
      transformResponseMailingListReturn,
      { customOptions?: RequestOptionsType } | void
    >({
      query: (args) => ({
        url: getMailingURL(),
        customOptions: args?.customOptions,
      }),
      providesTags: [
        'patchTask',
        'postTask',
        'deleteHhUser',
        'startTask',
        'stopTask',
        'deleteMailing',
      ],
      transformResponse: transformResponseMailingList,
      async onCacheEntryAdded(
        _,
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved },
      ) {
        // create a websocket connection when the cache subscription starts
        let ws;
        try {
          ws = new WSInstance(getMailingURL());
          // wait for the initial query to resolve before proceeding
          await cacheDataLoaded;

          // when data is received from the socket connection to the server,
          // if it is a message and for the appropriate channel,
          // update our query result with the received message
          const listener = (event: MessageEvent) => {
            updateCachedData(() =>
              transformResponseMailingList(JSON.parse(event.data)),
            );
          };

          ws.addEventListener('message', listener);
        } catch (error) {
          console.error(error);
          // no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded`,
          // in which case `cacheDataLoaded` will throw
        }
        // cacheEntryRemoved will resolve when the cache subscription is no longer active
        await cacheEntryRemoved;
        // perform cleanup steps once the `cacheEntryRemoved` promise resolves
        ws && ws.close();
      },
    }),

    createMailing: builder.mutation<
      RetrieveMailing,
      { data: CreateMailingRequest; customOptions?: RequestOptionsType }
    >({
      query: ({ data, customOptions }) => ({
        url: getMailingURL(),
        method: 'post',
        data,
        customOptions,
      }),
      invalidatesTags: ['postTask'],
    }),

    updateMailing: builder.mutation<
      RetrieveMailing,
      {
        id: number;
        patchedMailing: PatchedCreateMailingRequest;
        customOptions?: RequestOptionsType;
      }
    >({
      query: ({ id, patchedMailing, customOptions }) => ({
        url: getMailingURL(`/${id}/`),
        method: 'PATCH',
        data: patchedMailing,
        customOptions,
      }),
      invalidatesTags: ['patchTask'],
    }),

    deleteMailing: builder.mutation<
      void,
      {
        id: number;
      }
    >({
      query: ({ id }) => ({
        url: getMailingURL(`/${id}/`),
        method: 'DELETE',
      }),
      invalidatesTags: ['deleteMailing'],
    }),

    getCodeRegions: builder.query<
      TRegionCodesItem[],
      { customOptions?: RequestOptionsType } | void
    >({
      query: (args) => ({
        url: getRegionCodesURL(),
        method: 'get',
        customOptions: args?.customOptions,
      }),
    }),
    getSearchLink: builder.mutation<
      string,
      { data: SearchLinkRequest; customOptions?: RequestOptionsType }
    >({
      query: ({ data, customOptions }) => ({
        url: '/mailings/search_link/',
        method: 'post',
        data,
        customOptions,
      }),
    }),
    refreshEmployersList: builder.mutation<
      { employerOptions: { value: number; label: string }[] },
      {
        data: PatchedHhEmployerUpdateRequest;
        customOptions?: RequestOptionsType;
      }
    >({
      query: ({ data, customOptions }) => ({
        url: getEmployersURL('/refresh/'),
        method: 'patch',
        data,
        customOptions: customOptions,
      }),
      transformResponse: (data: Employer[]) => {
        return {
          employerOptions: data.map(({ id, name }) => ({
            value: id,
            label: name,
          })),
        };
      },
    }),
    createEmployer: builder.mutation<
      { employer: Employer; employerOption: { value: number; label: string } },
      { data: EmployerRequest; customOptions?: RequestOptionsType }
    >({
      query: ({ data, customOptions }) => ({
        url: getEmployersURL('/create_or_return/'),
        method: 'post',
        customOptions: customOptions,
        data: data,
      }),
      invalidatesTags: ['createEmployer'],
      transformResponse: (data: Employer) => {
        return {
          employer: data,
          employerOption: { value: data.id, label: data.name },
        };
      },
    }),
    deleteEmployer: builder.mutation<
      Employer,
      { id: string | number; customOptions?: RequestOptionsType }
    >({
      query: ({ id, customOptions }) => ({
        url: getEmployersURL(`/${id}/`),
        method: 'delete',
        customOptions: customOptions,
      }),
      invalidatesTags: ['deleteEmployer'],
    }),
  }),
});
type transformResponseMailingListReturn = EntityState<RetrieveMailing> & {
  result: RetrieveMailing[];
};
const mailingEntity = createEntityAdapter<RetrieveMailing>();
function transformResponseMailingList(
  result: RetrieveMailing[],
): transformResponseMailingListReturn {
  const normalizeMailingList = mailingEntity.addMany(
    mailingEntity.getInitialState(),
    result,
  );

  return {
    result,
    ids: normalizeMailingList.ids,
    entities: normalizeMailingList.entities,
  };
}

export const {
  useGetMailingByIdQuery,
  useGetMailingListQuery,
  useUpdateMailingMutation,
  useStartMailingMutation,
  useStopMailingMutation,
  useCreateMailingMutation,
  useGetCodeRegionsQuery,
  useGetSearchLinkMutation,
  useRefreshEmployersListMutation,
  useCreateEmployerMutation,
  useDeleteMailingMutation,
} = mailingApi;
