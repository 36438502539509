import { pokemonApi } from '../../RTKService';
import { RequestOptionsType } from '../../../request';
import { WSInstance } from '../../../instance';

import {
  getEmployersURL,
  getMailingURL,
  getRegionCodesURL,
} from '../../../getURLHelpers/getURLHelpers';
import { TRegionCodesItem } from '../../types/mailing-types/mailing.types';
import {
  CreateMailingRequest,
  Employer,
  EmployerRequest,
  PatchedCreateMailingRequest,
  RetrieveMailing,
} from '../../../../Entities';

export const mailingApi = pokemonApi.injectEndpoints({
  endpoints: (builder) => ({
    startMailing: builder.mutation<
      'Task was started',
      { id: number; customOptions?: RequestOptionsType }
    >({
      query: ({ id, customOptions }) => ({
        url: getMailingURL(`/${id}/start/`),
        method: 'post',
        customOptions,
      }),
      invalidatesTags: ['startTask'],
    }),
    stopMailing: builder.mutation<
      'Task was stopped',
      { id: number; customOptions?: RequestOptionsType }
    >({
      query: ({ id, customOptions }) => ({
        url: getMailingURL(`/${id}/stop/`),
        method: 'post',
        customOptions,
      }),
      invalidatesTags: ['stopTask'],
    }),

    getMailingById: builder.query<
      RetrieveMailing & { restricted_employerId: number[] },
      { id: number; customOptions?: RequestOptionsType }
    >({
      query: ({ id, customOptions }) => ({
        url: getMailingURL(`/${id}/`),
        customOptions,
      }),
      transformResponse: (response: RetrieveMailing) => {
        return {
          restricted_employerId: response.restricted_employers.map(
            (employer) => employer.id,
          ),
          ...response,
        };
      },
      async onCacheEntryAdded(
        { id },
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved },
      ) {
        // create a websocket connection when the cache subscription starts
        let ws;
        try {
          ws = new WSInstance(getMailingURL(`/${id}/`));
          // wait for the initial query to resolve before proceeding
          await cacheDataLoaded;

          // when data is received from the socket connection to the server,
          // if it is a message and for the appropriate channel,
          // update our query result with the received message
          const listener = (event: MessageEvent) => {
            updateCachedData(() => JSON.parse(event.data));
          };

          ws.addEventListener('message', listener);
        } catch (error) {
          console.error(error);
          // no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded`,
          // in which case `cacheDataLoaded` will throw
        }
        // cacheEntryRemoved will resolve when the cache subscription is no longer active
        await cacheEntryRemoved;
        // perform cleanup steps once the `cacheEntryRemoved` promise resolves
        ws && ws.close();
      },
    }),

    getMailingList: builder.query<
      RetrieveMailing[],
      { customOptions?: RequestOptionsType } | void
    >({
      query: (args) => ({
        url: getMailingURL(),
        customOptions: args?.customOptions,
      }),
      providesTags: ['postTask', 'deleteHhUser'],
    }),

    createMailing: builder.mutation<
      RetrieveMailing,
      { data: CreateMailingRequest; customOptions?: RequestOptionsType }
    >({
      query: ({ data, customOptions }) => ({
        url: getMailingURL(),
        method: 'post',
        data,
        customOptions,
      }),
      invalidatesTags: ['postTask'],
    }),

    updateMailing: builder.mutation<
      RetrieveMailing,
      {
        id: number;
        patchedMailing: PatchedCreateMailingRequest;
      }
    >({
      query: ({ id, patchedMailing }) => ({
        url: getMailingURL(`/${id}/`),
        method: 'PATCH',
        data: patchedMailing,
      }),
      invalidatesTags: ['patchTask'],
    }),
    getCodeRegions: builder.query<
      TRegionCodesItem[],
      { customOptions?: RequestOptionsType }
    >({
      query: ({ customOptions }) => ({
        url: getRegionCodesURL(),
        method: 'get',
        customOptions,
      }),
    }),
    getSearchLink: builder.query<
      string,
      { id: number; customOptions?: RequestOptionsType }
    >({
      query: ({ id, customOptions }) => ({
        url: getMailingURL(`/${id}/search_link/`),
        method: 'get',
        customOptions,
      }),
      providesTags: ['patchTask'],
    }),
    getEmployerList: builder.query<
      Employer[],
      { customOptions?: RequestOptionsType } | void
    >({
      query: (params) => ({
        url: getEmployersURL(),
        method: 'get',
        customOptions: params?.customOptions,
      }),
      providesTags: ['createEmployer'],
    }),
    getEmployer: builder.query<
      Employer,
      { id: number; customOptions?: RequestOptionsType }
    >({
      query: (params) => ({
        url: getEmployersURL(`/${params.id}/`),
        method: 'get',
        customOptions: params?.customOptions,
      }),
    }),
    createEmployer: builder.mutation<
      Employer,
      { data: EmployerRequest; customOptions?: RequestOptionsType }
    >({
      query: ({ data, customOptions }) => ({
        url: getEmployersURL(),
        method: 'post',
        customOptions: customOptions,
        data: data,
      }),
      invalidatesTags: ['createEmployer'],
    }),
  }),
});

export const {
  useGetMailingByIdQuery,
  useGetMailingListQuery,
  useUpdateMailingMutation,
  useStartMailingMutation,
  useStopMailingMutation,
  useCreateMailingMutation,
  useGetCodeRegionsQuery,
  useGetSearchLinkQuery,
  useGetEmployerListQuery,
  useCreateEmployerMutation,
} = mailingApi;
