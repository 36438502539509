import { useFormContext } from 'react-hook-form';
import { WithHookFormControlSelector } from '../../../../Components/UI-kit/Selector/WithHookFormControlSelector';
import { HookFormTextInput as TextInput } from '../../../../Components/UI-kit/TextInput/HookFormTextInput';
import { WithHookFormControlTextArea as TextArea } from '../../../../Components/UI-kit/TextArea/WithHookFormControlTextArea';
import styles from './Selectors.module.scss';
import {
  CreateMailingRequest,
  PatchedCreateMailingRequest,
} from '../../../../Entities';
import { MenuPlacement, SelectorType } from 'Components/UI-kit/Selector';
import { RestrictedEmployersContainer } from './RestrictedEmployersContainer/RestrictedEmployersContainer';
import { ResumesContainer } from './ResumesContainer/ResumesContainer';
import { RegionContainer } from './RegionContainer/RegionContainer';

import {
  employmentTypeOptions,
  experienceOptions,
  orderOptions,
  scheduleOptions,
  searchPeriodOptions,
} from './SelectorDefaultOptions.data';

export const UpdateSelectors = () => {
  const { control } = useFormContext<
    CreateMailingRequest | PatchedCreateMailingRequest
  >();

  return (
    <div className={styles.container}>
      <ResumesContainer />
      <RegionContainer />
      <TextInput
        control={control}
        name={'search_key'}
        label={'Желаемая должность'}
        placeholder={'Укажите должность'}
      />

      <TextArea
        control={control}
        label={'Сопроводительное письмо (необязательно)'}
        name={'letter'}
        placeholder={'Сопроводительное письмо'}
        rows={5}
      />

      <WithHookFormControlSelector
        name={'order'}
        control={control}
        placeholder={'Сортировать вакансии'}
        options={orderOptions}
        type={SelectorType.Form}
      />
      <WithHookFormControlSelector
        name={'search_period'}
        control={control}
        placeholder={'Откликаться на вакансии, опубликованные'}
        menuPlacement={MenuPlacement.Top}
        options={searchPeriodOptions}
        type={SelectorType.Form}
      />

      <WithHookFormControlSelector
        name={'employment_type'}
        control={control}
        placeholder={'Тип вакансии'}
        menuPlacement={MenuPlacement.Top}
        options={employmentTypeOptions}
        type={SelectorType.Form}
      />
      <WithHookFormControlSelector
        name={'schedule'}
        control={control}
        placeholder={'График вакансии'}
        menuPlacement={MenuPlacement.Top}
        options={scheduleOptions}
        type={SelectorType.Form}
      />
      <WithHookFormControlSelector
        name={'experience'}
        control={control}
        placeholder={'Опыт работы'}
        menuPlacement={MenuPlacement.Top}
        options={experienceOptions}
        type={SelectorType.Form}
      />
      <RestrictedEmployersContainer />
    </div>
  );
};
