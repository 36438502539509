import { format } from 'date-fns';
import './Stats.css';
import {
  useGetMailingByIdQuery,
  useGetSearchLinkQuery,
} from '../../services/RTKService/mailing/endpoints/mailingApi';
import { StatusEnum } from '../../Entities';

const MAILING_STATUS_CONST: Record<
  StatusEnum,
  { text: string; color: string }
> = {
  RUNNING: { text: 'Запущенно', color: '#ff7512' },
  STOPPED: { text: 'Остановленно', color: '#d8ac12' },
  PENDING: { text: 'В обработке', color: '#ff7512' },
  ERROR: { text: 'Ошибка', color: '#bb292b' },
  PLAN_FULFILLED: { text: 'Полностью выполнено', color: '#13a463' },
  DAILY_PLAN_FULFILLED: { text: 'На сегодня выполнено', color: '#13a463' },
  OUT_OF_VACANCIES: { text: 'Вакансии отсутствуют', color: '#bb292b' },
  HH_LIMIT_REACHED: { text: 'Достигнут лимит', color: '#d8ac12' },
  CREATED: { text: 'Созданно', color: '#d8ac12' },
};

interface props {
  taskId?: number;
  className?: string;
}

export default function Stats({ taskId }: props) {
  const { statistics, mailingStatus } = useGetMailingByIdQuery(
    { id: taskId as number },
    {
      skip: !taskId,
      selectFromResult: ({ data }) => ({
        statistics: data && {
          total: data.applies_count,
          perDay: data.applies_count_today,
          desired_applies_number_per_day: data.desired_applies_number_per_day,
          desired_number_of_applies: data.desired_number_of_applies,
          lastRunTime: data.last_period_first_run_time,
          nextRunTime: data.next_run_time,
        },
        mailingStatus: data?.status && MAILING_STATUS_CONST[data?.status],
        data,
      }),
    },
  );

  const { link } = useGetSearchLinkQuery(
    { id: taskId as number },
    {
      skip: !taskId,
      selectFromResult: ({ data }) => ({
        link: data,
      }),
    },
  );

  if (!statistics && !link) return null;

  return (
    <div className='flex flex-col space-y-1 px-4 py-5 bg-gray-200 shadow-xl rounded-lg overflow-hidden'>
      {mailingStatus ? (
        <div className='font-medium text-gray-500 truncate'>
          Статус рассылки:{' '}
          <span style={{ color: mailingStatus.color }}>
            {mailingStatus.text}
          </span>
        </div>
      ) : null}
      {statistics ? (
        <>
          <div className=' font-medium text-gray-500 truncate'>
            Количество откликов сегодня: {statistics.perDay} из{' '}
            {statistics.desired_applies_number_per_day}
          </div>
          <div className=' font-medium text-gray-500 truncate'>
            Количество откликов всего: {statistics.total} из{' '}
            {statistics.desired_number_of_applies}{' '}
          </div>
          {statistics.lastRunTime ? (
            <div className=' font-medium text-gray-500 truncate'>
              Последний запуск:{' '}
              {format(statistics.lastRunTime, 'dd-MM-yyyy HH:mm:ss')}
            </div>
          ) : null}
          {statistics.nextRunTime ? (
            <div className=' font-medium text-gray-500 truncate'>
              Следующий запуск:{' '}
              {format(statistics.nextRunTime, 'dd-MM-yyyy HH:mm:ss')}
            </div>
          ) : null}
        </>
      ) : null}
      {link ? (
        <div className='font-medium text-gray-500 truncate'>
          Отклики будут разосланы на следующие вакансии:{' '}
          <a
            className='font-extralight text-indigo-600 truncate hover:underline'
            target='_blank'
            href={link}
            rel='noreferrer'
          >
            Ссылка на страницу поиска вакансий
          </a>
        </div>
      ) : null}
    </div>
  );
}
