import { useForm, UseFormSetValue } from 'react-hook-form';
import { useGetMailingByIdQuery } from '../../../services/RTKService/mailing/endpoints/mailingApi';
import { useEffect } from 'react';
import { entries } from '../../../Shared/Utils/typedEntries';

import { useUpdateMailingTask } from './useUpdateMailingTask';
import { useCreateMailingTask } from './useCreateMailingTask';
import { CreateMailingRequest, RetrieveMailing } from '../../../Entities';

const setMailingForms = (
  mailingTask:
    | (RetrieveMailing & { restricted_employerId: number[] })
    | undefined,
  setValue: UseFormSetValue<CreateMailingRequest>,
) => {
  if (mailingTask) {
    const {
      region,
      resume_hash,
      search_period,
      sleep_time,
      desired_applies_number_per_day,
      order,
      letter,
      employment_type,
      hh_user,
      desired_number_of_applies,
      desired_vacancy_search_key,
      restricted_employerId,
      schedule,
      experience,
    } = mailingTask;

    const formState: CreateMailingRequest = {
      region,
      restricted_employers: restricted_employerId,
      resume_hash,
      search_period,
      sleep_time,
      desired_applies_number_per_day,
      order,
      letter,
      employment_type,
      hh_user,
      desired_number_of_applies,
      desired_vacancy_search_key,
      schedule,
      experience,
    };

    entries(formState).forEach((value) => {
      if (!value) return;
      setValue(value[0], value[1]);
    });
  }
};

export const useMailingSettings = (id?: number) => {
  const {
    formState: { dirtyFields },
    control,
    handleSubmit,
    setValue,
    trigger,
    watch,
  } = useForm<CreateMailingRequest>();

  const isChanged = Object.keys(dirtyFields).length !== 0;

  const {
    data: mailingTask,
    isLoading,
    refetch,
  } = useGetMailingByIdQuery(
    { id: id as number },
    {
      skip: !id,
    },
  );
  useEffect(() => {
    if (mailingTask) setMailingForms(mailingTask, setValue);
  }, [mailingTask]);

  // ---> Подглядываем за desired_applies_number_per_day полем и
  // если оно превышает desired_number_of_applies поле,
  // то меняем поле desired_number_of_applies так,
  // чтобы desired_applies_number_per_day не превышал desired_number_of_applies
  // и если desired_applies_number_per_day равен 0,
  // то и desired_number_of_applies сбрасывается на 0.
  // Следим за *desired_number_of_applies*, триггерим валидацию на каждое изменение поля.
  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === 'desired_applies_number_per_day') {
        if (
          value.desired_number_of_applies === undefined ||
          value.desired_applies_number_per_day === undefined
        )
          return;

        if (
          +value.desired_number_of_applies <
          +value.desired_applies_number_per_day
        ) {
          setValue('desired_number_of_applies', value[name]);
          trigger('desired_number_of_applies');
        }
      } else if (name === 'desired_number_of_applies') {
        if (
          value.desired_number_of_applies === undefined ||
          value.desired_applies_number_per_day === undefined
        )
          return;

        trigger('desired_number_of_applies');
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);
  // <---

  const reset = () => {
    if (mailingTask) setMailingForms(mailingTask, setValue);
  };

  const { updateTask, isLoading: isUpdateLoading } = useUpdateMailingTask();
  const { createTask, isLoading: isCreateLoading } = useCreateMailingTask();

  const onSubmit = handleSubmit(async (data) => {
    if (id) {
      await updateTask(id, data, mailingTask?.status);
    } else {
      await createTask(data);
    }
    refetch();
  });

  return {
    control,
    onSubmit,
    isLoading,
    isUpdateLoading: isUpdateLoading || isCreateLoading,
    isChanged,
    reset,
  };
};
