import { useOutsideClick } from 'hooks/useOutsideClick';
import { useId, useRef, useState } from 'react';
import styles from './Selector.module.scss';
import { Combobox } from './Combobox/Combobox';
import { ListBox, ListBoxProps } from './ListBox/ListBox';
import {
  MenuPlacement,
  SelectorProps,
  SelectorType,
  SelectorValue,
} from './types';

// Some a11y-related properties and logic are taken from official w3c examples:
// https://www.w3.org/WAI/ARIA/apg/patterns/combobox/examples/combobox-select-only/
export const Selector = <T extends SelectorValue>({
  value,
  changeValue,
  options,
  type,
  placeholder,
  tag,
  className,
  menuPlacement = MenuPlacement.Bottom,
  onCreateOption,
  onDeleteOption,
  isAdaptiveWidth = false,
  isSearchable = false,
  error,
  ...otherProps
}: SelectorProps) => {
  const [areOptionsShown, setShowOptions] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [inputValue, setInputValue] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const comboboxRef = useRef<HTMLDivElement>(null);
  const listboxRef = useRef<HTMLDivElement>(null);

  useOutsideClick([comboboxRef, listboxRef], () => setShowOptions(false));

  const onChangeSearchValue = (inputValue: string) => {
    setSearchValue(inputValue);
  };

  const onHandleOption: ListBoxProps['onHandleOption'] = ({
    selectValue,
    index,
  }) => {
    if (Array.isArray(value)) {
      changeValue([...new Set([...value, selectValue])]);
    } else {
      changeValue(selectValue);
      setShowOptions(false);
    }
    setActiveIndex(index);
    onChangeSearchValue('');
  };

  // id of the selector element,needed for a11y
  const selectorId = useId();

  const onHandleCreateOption = onCreateOption
    ? async () => {
        if (!inputValue.trim()) return;
        const response = await onCreateOption(inputValue);
        if (response) setInputValue('');
      }
    : undefined;

  const onHandlePastToClipboard = async () => {
    const text = await navigator.clipboard.readText();
    setInputValue(text);
  };

  // вычисляем ширину контейнера, в котором находятся combobox и listbox
  const listboxWidth = listboxRef.current?.clientWidth;
  const comboboxWidth = comboboxRef.current?.clientWidth;
  const positionRelativeContainerWidth =
    comboboxWidth && listboxWidth
      ? Math.max(listboxWidth, comboboxWidth)
      : undefined;

  return (
    <div
      className={[styles[type], styles.selector, className].join(' ')}
      {...otherProps}
    >
      {/* hidden label for semantics */}
      <label
        id={selectorId + '-label'}
        className={type === SelectorType.Form ? '' : 'visually-hidden'}
      >
        {placeholder}
      </label>
      {/* display-value */}
      <div
        className={styles.positionRelativeContainer}
        style={
          isAdaptiveWidth && positionRelativeContainerWidth
            ? { minWidth: `${positionRelativeContainerWidth}px` }
            : undefined
        }
      >
        <Combobox
          isSearchable={isSearchable}
          placeholder={placeholder}
          onChangeSearchValue={onChangeSearchValue}
          searchValue={searchValue}
          setActiveIndex={setActiveIndex}
          setShowOptions={setShowOptions}
          onHandleOption={onHandleOption}
          options={options}
          value={value}
          changeValue={changeValue}
          selectorId={selectorId}
          activeIndex={activeIndex}
          comboboxRef={comboboxRef}
          tag={tag}
          areOptionsShown={areOptionsShown}
        />
        <ListBox
          onChangeSearchValue={onChangeSearchValue}
          inputValue={inputValue}
          setInputValue={setInputValue}
          onHandlePastToClipboard={onHandlePastToClipboard}
          onCreateOption={onHandleCreateOption}
          options={options}
          searchValue={searchValue}
          value={value}
          menuPlacement={menuPlacement}
          areOptionsShown={areOptionsShown}
          listboxRef={listboxRef}
          selectorId={selectorId}
          activeIndex={activeIndex}
          onHandleOption={onHandleOption}
          setActiveIndex={setActiveIndex}
          onDeleteOption={onDeleteOption}
        />
        {error && <p className={styles.errorMessage}>{error}</p>}
      </div>
    </div>
  );
};
