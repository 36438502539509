/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `PLAN_FULFILLED` - Все отклики разосланы
 * * `DAILY_PLAN_FULFILLED` - Дневной план на рассылку выполнен
 * * `OUT_OF_VACANCIES` - Закончились вакансии по данному запросу
 * * `HH_LIMIT_REACHED` - Достигнут лимит запросов, установленный hh.ru
 * * `RUNNING` - Запущена
 * * `CREATED` - Создана
 * * `PENDING` - Ожидает очереди
 * * `STOPPED` - Остановлена
 * * `ERROR` - Ошибка
 */
export enum StatusEnum {
  PLAN_FULFILLED = 'PLAN_FULFILLED',
  DAILY_PLAN_FULFILLED = 'DAILY_PLAN_FULFILLED',
  OUT_OF_VACANCIES = 'OUT_OF_VACANCIES',
  HH_LIMIT_REACHED = 'HH_LIMIT_REACHED',
  RUNNING = 'RUNNING',
  CREATED = 'CREATED',
  PENDING = 'PENDING',
  STOPPED = 'STOPPED',
  ERROR = 'ERROR',
}
