import { createSelector } from '@reduxjs/toolkit';
import { hhUserApi } from '../endpoints/hhUserApi';

const selectHhUserResult = hhUserApi.endpoints.getHhUser.select({
  customOptions: { showErrorToast: false },
});

export const selectHhUserIsLoading = createSelector(
  selectHhUserResult,
  ({ isLoading }) => isLoading,
);

export const _selectHhUser = createSelector(selectHhUserResult, ({ data }) => {
  if (!data) {
    return null;
  }
  if (data.length === 0) {
    return null;
  }
  return data[0];
});

export const selectHhUserId = createSelector(selectHhUserResult, ({ data }) => {
  if (!data) {
    return null;
  }
  if (data.length === 0) {
    return null;
  }
  return data[0].id ?? data[0].hh_id;
});
