/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `REGULAR` - Обычная вакансия
 * * `QUESTIONNAIRE` - Вакансия с анкетой
 * * `EXTRA_QUESTIONS` - Вакансия с вопросами
 * * `DIRECT_RESPONSE` - Вакансия с прямым откликом
 * * `OTHER` - Не была найдена кнопка отклика
 */
export enum VacancyTypeEnum {
  REGULAR = 'REGULAR',
  QUESTIONNAIRE = 'QUESTIONNAIRE',
  EXTRA_QUESTIONS = 'EXTRA_QUESTIONS',
  DIRECT_RESPONSE = 'DIRECT_RESPONSE',
  OTHER = 'OTHER',
}
