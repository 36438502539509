import React, { memo } from 'react';
import { SelectorWrapper } from './SelectorWrapper';
import { Control, Controller } from 'react-hook-form';
import CreatableSelect from 'react-select/creatable';
import image2 from 'assets/images/screenShot1.png';
import image3 from 'assets/images/screenShot2.png';
import {
  useCreateEmployerMutation,
  useGetEmployerListQuery,
} from '../../../../../services/RTKService/mailing/endpoints/mailingApi';
import {
  ISelectOption,
  ReactSelector,
} from '../../../../../Components/Selector/ReactSelector/ReactSelector';
import { TooltipVariants } from 'Components/ToolTip/ToolTip';
import { CreateMailingRequest } from '../../../../../Entities';

interface IHookFormEmploymentTypeSelectorProps {
  control: Control<CreateMailingRequest>;
}

export const HookFormRestrictedEmployersSelector: React.FC<IHookFormEmploymentTypeSelectorProps> =
  memo(({ control }) => {
    const { data: options, isLoading: isLoadingEmployerList } =
      useGetEmployerListQuery(
        {},
        {
          selectFromResult: ({ data, ...params }) => {
            let selectData: ISelectOption[] = [];
            if (data) {
              selectData = data.map((employer) => ({
                value: employer.id,
                label: employer.name,
              }));
            }

            return { data: selectData, ...params };
          },
        },
      );

    const [create, { isLoading: isLoadingNewEmployer }] =
      useCreateEmployerMutation();

    return (
      <SelectorWrapper
        toolTipProps={{
          variants: TooltipVariants.topRight,
        }}
        toolTipContent={<ToolTipContent />}
        label={'Нежелательные компании'}
      >
        <Controller
          control={control}
          name={'restricted_employers'}
          render={({ field: { onChange, onBlur, value } }) => {
            return (
              <ReactSelector
                onChange={onChange}
                value={value}
                options={options}
                isMulti
                renderComponent={({ value: getValue, onChange, options }) => {
                  const handleCreate = async (inputValue: string) => {
                    const res = await create({ data: { link: inputValue } });
                    if ('data' in res) {
                      const newEmployer = {
                        value: res.data.id,
                        label: res.data.name,
                      };
                      onChange([newEmployer, ...getValue(value)]);
                    }
                  };
                  return (
                    <CreatableSelect
                      isLoading={isLoadingEmployerList || isLoadingNewEmployer}
                      formatCreateLabel={(inputValue: string) => (
                        <span>Добавить: {inputValue}</span>
                      )}
                      onBlur={onBlur}
                      menuPlacement={'top'}
                      isMulti
                      isClearable
                      onChange={onChange}
                      onCreateOption={handleCreate}
                      options={options}
                      value={getValue(value)}
                      placeholder={'Вставьте ссылку сюда'}
                    />
                  );
                }}
              />
            );
          }}
        />
      </SelectorWrapper>
    );
  });

const ToolTipContent = () => {
  return (
    <div className='flex flex-col w-max gap-3  overflow-hidden z-auto'>
      <img
        className={'object-cover rounded-xl border-black border-2'}
        src={image2}
        alt='image2'
      />
      <img
        className={'object-cover rounded-xl border-black border-2'}
        src={image3}
        alt='image3'
      />
    </div>
  );
};
