import { SelectorType } from 'Components/UI-kit/Selector/types';
import { MenuPlacement } from 'Components/UI-kit/Selector/types';
import { WithHookFormControlSelector } from 'Components/UI-kit/Selector/WithHookFormControlSelector';
import { CreateMailingRequest } from 'Entities/models/CreateMailingRequest';
import { PatchedCreateMailingRequest } from 'Entities/models/PatchedCreateMailingRequest';
import { useEmployerOptions } from 'hooks/useEmployerOptions';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

export const RestrictedEmployersContainer = () => {
  const { control, watch, setValue, getValues } = useFormContext<
    CreateMailingRequest | PatchedCreateMailingRequest
  >();

  const { createOption, deleteOption, getOptions, employerOptions } =
    useEmployerOptions();

  useEffect(() => {
    getOptions(watch('restricted_employers'));
  }, []);

  const onCreateOption = async (inputValue: string) => {
    if (!inputValue.trim()) return;
    const response = await createOption({ link: inputValue });
    if (!response) return;
    const restricted_employers = getValues('restricted_employers');
    const new_restricted_employers = [
      ...new Set([...(restricted_employers ?? []), response.value]),
    ];
    setValue('restricted_employers', new_restricted_employers);

    return 'clearInput';
  };

  const onDeleteOption = async (id: string | number) => {
    await deleteOption(Number(id));
  };
  return (
    <WithHookFormControlSelector
      name={'restricted_employers'}
      control={control}
      placeholder={'Запрещенные работодатели'}
      menuPlacement={MenuPlacement.Top}
      options={employerOptions}
      type={SelectorType.Form}
      onCreateOption={onCreateOption}
      onDeleteOption={onDeleteOption}
    />
  );
};
