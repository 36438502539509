import { Button, ButtonType } from '../Buttons/Button';
import { ProgressBar, ProgressBarColor } from '../ProgressBar/ProgressBar';
import { ResponseCounter } from '../ResponseCounter/ResponseCounter';
import styles from './MailingCard.module.scss';
import { MailingCardStatus } from './MailingCardStatus/MailingCardStatus';
import { useGetHhUserListQuery } from '../../../services/RTKService/hhUser/endpoints/hhUserApi';
import { RetrieveMailing, StatusEnum } from '../../../Entities';
import { useGetCodeRegionsQuery } from '../../../services/RTKService/mailing/endpoints/mailingApi';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import { NavigationPath } from '../../../config/navigation.data';
import { useNavigate } from 'react-router-dom';
import { StopButton } from './StopButton/StopButton';
import { StartButton } from './StartButton/StartButton';
import { DeleteButton } from './DeleteButton/DeleteButton';
import { ReactComponent as EditIcon } from 'assets/svg/editMailingCard.svg';
import { ReactComponent as DeleteIcon } from 'assets/svg/delete.svg';
import { ContinueButton } from './ContinueButton/ContinueButton';

export type MailingInfo = Pick<
  RetrieveMailing,
  | 'id'
  | 'hh_user'
  | 'search_key'
  | 'areas'
  | 'start_time'
  | 'next_run_time'
  | 'applies_count'
  | 'applies_count_today'
  | 'plan'
  | 'daily_plan'
  | 'status'
>;

interface Props {
  mailingInfo: MailingInfo;
}

export const MailingCard = ({ mailingInfo }: Props) => {
  const {
    id,
    search_key,
    areas,
    next_run_time,
    hh_user,
    applies_count_today,
    applies_count,
    start_time,
    status,
    daily_plan,
    plan,
  } = mailingInfo || {};

  const navigate = useNavigate();

  const {
    data: { normalizeHhUserList: { entities: hhUserEntities = {} } = {} } = {},
  } = useGetHhUserListQuery();
  //todo: возможно бага
  const { regionSelect } = useGetCodeRegionsQuery(undefined, {
    selectFromResult: ({ data }) => ({
      // regionSelect: data?.find(({ value }) => value === areas),
      regionSelect: data?.filter((area) =>
        areas?.some((ar) => ar === area.value),
      ),
    }),
  });

  const startStoprenderButton = (status: StatusEnum) => {
    switch (status) {
      case StatusEnum.CREATED:
        return (
          <StartButton className={styles.startStopButtons} mailing_id={id}>
            Начать
          </StartButton>
        );

      case StatusEnum.RUNNING:
        return (
          <StopButton className={styles.startStopButtons} mailing_id={id}>
            Поставить на паузу
          </StopButton>
        );
      case StatusEnum.STOPPED:
        return (
          <StartButton className={styles.startStopButtons} mailing_id={id}>
            Возобновить
          </StartButton>
        );
      case StatusEnum.PENDING:
      case StatusEnum.DAILY_PLAN_COMPLETED:
        return (
          <StopButton className={styles.startStopButtons} mailing_id={id}>
            Поставить на паузу
          </StopButton>
        );
      case StatusEnum.PLAN_COMPLETED:
        return <ContinueButton className={styles.startStopButtons} id={id} />;
      case StatusEnum.HH_LIMIT_REACHED:
      case StatusEnum.OUT_OF_VACANCIES:
      case StatusEnum.ERROR:
        return null;
    }
  };

  const onHandleEdit = () => {
    navigate({
      pathname: NavigationPath.mailingEdit,
      search: `id=${id}`,
    });
  };

  const EditMailingButtonRender = (status: StatusEnum) => {
    switch (status) {
      case StatusEnum.RUNNING:
      case StatusEnum.PENDING:
      case StatusEnum.PLAN_COMPLETED:
      case StatusEnum.DAILY_PLAN_COMPLETED:
        return (
          <Button
            Icon={EditIcon}
            disabled
            interfaceType={ButtonType.Primary}
            onClick={onHandleEdit}
          >
            Изменить
          </Button>
        );
      default:
        return (
          <Button
            Icon={EditIcon}
            interfaceType={ButtonType.Primary}
            onClick={onHandleEdit}
          >
            Изменить
          </Button>
        );
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.headBlock}>
        <MailingCardStatus statusVariant={status} />
        <div className={styles.titleAndRegion}>
          <div className={styles.title}>
            <h3>{search_key}</h3>
          </div>
          {regionSelect?.map((area) => (
            <p key={area.value} className={styles.region}>
              {area?.label}
            </p>
          ))}
        </div>
      </div>
      <div className={styles.accountBlock}>
        <h4 className={styles.account}>Аккаунт</h4>
        <p className={styles.email}>{hhUserEntities[hh_user]?.email}</p>
      </div>
      <div className={styles.dateBlock}>
        <div className={styles.startDate}>
          <p>Первый запуск</p>
          <p>
            {start_time &&
              format(new Date(start_time), 'dd.MM.yyyy HH:mm', { locale: ru })}
          </p>
        </div>
        <div className={styles.stopDate}>
          <p>Следующий запуск</p>
          <p>
            {next_run_time &&
              format(new Date(next_run_time), 'dd.MM.yyyy HH:mm', {
                locale: ru,
              })}
          </p>
        </div>
      </div>
      <div className={styles.StatisticsBlock}>
        <h4>Разослано</h4>
        <div className={styles.statisticsInfo}>
          <div className={styles.innerBlock}>
            <div>
              <p>Всего</p>
              <ProgressBar count={applies_count} totalCount={plan} />
            </div>
            <div>
              <ResponseCounter count={applies_count} totalCount={plan} />
              <p>откликов</p>
            </div>
          </div>
          <div className={styles.innerBlock}>
            <div>
              <p>За день</p>
              <ProgressBar
                color={ProgressBarColor.Yellow}
                count={applies_count_today}
                totalCount={daily_plan}
              />
            </div>
            <div>
              <ResponseCounter
                count={applies_count_today}
                totalCount={daily_plan}
              />
              <p>откликов</p>
            </div>
          </div>
        </div>
      </div>
      {startStoprenderButton(status)}
      <div className={styles.editDeleteButtons}>
        {EditMailingButtonRender(status)}
        <DeleteButton
          Icon={DeleteIcon}
          interfaceType={ButtonType.Primary}
          mailing_id={id}
        >
          Удалить
        </DeleteButton>
      </div>
    </div>
  );
};
