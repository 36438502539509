import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useRegisterMutation } from '../../services/RTKService/auth/endpoints/authApi';
import { addServerErrors } from '../../Shared/hookForm/addServerErrors';
import { UserRequest } from '../../Entities';

export const useSignUp = () => {
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    reset,
    setError,
    formState: { errors },
  } = useForm<UserRequest>();

  const [register, { isLoading }] = useRegisterMutation();

  const onSubmit = handleSubmit(async (data) => {
    const res = await register({
      data,
      customOptions: { showErrorToast: false },
    });
    if ('data' in res) {
      reset();
      navigate('/confirm-email');
    }
    if ('error' in res) {
      addServerErrors(res, setError);
    }
  });

  const onLink = () => {
    navigate('/login');
  };
  return {
    control,
    onSubmit,
    isLoading,
    onLink,
    errors,
  };
};
