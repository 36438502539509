import { HHCheckButton } from '../../../../Components/UI-kit/HHCheckButton/HHCheckButton';
import styles from './EditMailingStep2.module.scss';
import {
  Button,
  ButtonType,
} from '../../../../Components/UI-kit/Buttons/Button';
import { useGetSearchLinkMutation } from '../../../../services/RTKService/mailing/endpoints/mailingApi';
import { useNavigate } from 'react-router-dom';
import { NavigationPath } from '../../../../config/navigation.data';
import { useFormContext } from 'react-hook-form';
import { CreateMailingRequest } from 'Entities';
import { useEffect } from 'react';

export const EditMailingStep2 = () => {
  const { handleSubmit } = useFormContext<CreateMailingRequest>();
  const navigate = useNavigate();
  const [getSearchLink, { data }] = useGetSearchLinkMutation();

  useEffect(() => {
    const submit = handleSubmit(async (data) => {
      await getSearchLink({ data });
    });
    submit();
  }, []);

  const onHandleBack = () => {
    navigate({
      pathname: NavigationPath.mailingCreateStep1,
    });
  };

  const onHandleNext = () => {
    navigate({
      pathname: NavigationPath.mailingCreateStep3,
    });
  };

  return (
    <div className={styles.container}>
      <HHCheckButton link={data} disabled={!!data} />
      <div className={styles.buttons}>
        <Button onClick={onHandleBack} interfaceType={ButtonType.Secondary}>
          Нет, исправить
        </Button>

        <Button onClick={onHandleNext} interfaceType={ButtonType.Primary}>
          Да, всё верно
        </Button>
      </div>
    </div>
  );
};
