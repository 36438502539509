import { pokemonApi } from '../../RTKService';
import { getAuthURL } from '../../../getURLHelpers/getURLHelpers';
import { RequestOptionsType } from '../../../request';
import {
  Login,
  LoginRequest,
  PatchedUserRequest,
  Registration,
  RegistrationRequest,
  User,
} from '../../../../Entities';

export const authApi = pokemonApi.injectEndpoints({
  endpoints: (build) => ({
    getUser: build.query<User, { customOptions?: RequestOptionsType }>({
      query: ({ customOptions }) => ({
        url: getAuthURL('user'),
        method: 'get',
        customOptions,
      }),
      providesTags: ['login', 'patchUser'],
    }),
    register: build.mutation<
      Registration,
      { data: RegistrationRequest; customOptions?: RequestOptionsType }
    >({
      query: ({ data, customOptions }) => ({
        method: 'post',
        url: getAuthURL('register'),
        data,
        customOptions,
      }),
    }),
    login: build.mutation<
      Login,
      { data: LoginRequest; customOptions?: RequestOptionsType }
    >({
      query: ({ data, customOptions }) => ({
        method: 'post',
        url: getAuthURL('login'),
        data,
        customOptions,
      }),
      invalidatesTags: ['login'],
    }),
    updateUser: build.mutation<
      User,
      { data: PatchedUserRequest; customOptions?: RequestOptionsType }
    >({
      query: ({ data, customOptions }) => ({
        method: 'patch',
        url: getAuthURL('user'),
        data,
        customOptions,
      }),
      invalidatesTags: ['patchUser'],
    }),
  }),
});
export const {
  useGetUserQuery,
  useUpdateUserMutation,
  useLoginMutation,
  useRegisterMutation,
} = authApi;
