import { SelectorType } from 'Components/UI-kit/Selector/types';
import { WithHookFormControlSelector } from 'Components/UI-kit/Selector/WithHookFormControlSelector';
import { CreateMailingRequest } from 'Entities/models/CreateMailingRequest';
import { PatchedCreateMailingRequest } from 'Entities/models/PatchedCreateMailingRequest';
import { useFormContext } from 'react-hook-form';
import { useGetCodeRegionsQuery } from 'services/RTKService/mailing/endpoints/mailingApi';

export const RegionContainer = () => {
  const { control } = useFormContext<
    CreateMailingRequest | PatchedCreateMailingRequest
  >();

  const { data: regionOptions = [] } = useGetCodeRegionsQuery();
  return (
    <WithHookFormControlSelector
      name={'areas'}
      control={control}
      placeholder={'Регион'}
      options={regionOptions}
      type={SelectorType.Form}
      isSearchable={true}
    />
  );
};
