import { HookFormTextInput } from '../../../../Components/UI-kit/TextInput/HookFormTextInput';
import { Notification } from '../../../../Components/UI-kit/Notification/Notification';
import { useFormContext } from 'react-hook-form';
import styles from './DesiredAppliesInputs.module.scss';
import { CreateMailingRequest } from '../../../../ApiTypes';

export const DesiredAppliesInputs = () => {
  const { control } = useFormContext<CreateMailingRequest>();

  return (
    <div className={styles.container}>
      <div className={styles.inputs}>
        <HookFormTextInput
          control={control}
          name={'plan'}
          label={'Суммарное количество откликов'}
          type={'number'}
        />
        <HookFormTextInput
          control={control}
          name={'daily_plan'}
          label={'Количество откликов в день'}
          type={'number'}
        />
      </div>
      <Notification />
    </div>
  );
};
