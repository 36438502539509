/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `fullDay` - Полный день
 * * `shift` - Сменный график
 * * `flexible` - Гибкий график
 * * `remote` - Удаленная работа
 * * `flyInFlyOut` - Вахтовый метод
 */
export enum ScheduleEnum {
  FULL_DAY = 'fullDay',
  SHIFT = 'shift',
  FLEXIBLE = 'flexible',
  REMOTE = 'remote',
  FLY_IN_FLY_OUT = 'flyInFlyOut',
}
