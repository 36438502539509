import { pokemonApi } from '../../RTKService';

import { getHhUserURL } from '../../../getURLHelpers/getURLHelpers';
import { RequestOptionsType } from '../../../request';
import { HhUser, HhUserRequest } from '../../../../Entities';

export const hhUserApi = pokemonApi.injectEndpoints({
  endpoints: (build) => ({
    getHhUser: build.query<
      HhUser[],
      {
        customOptions?: RequestOptionsType;
        params?: { hh_data: boolean };
      }
    >({
      query: (args) => ({
        url: getHhUserURL(),
        customOptions: args?.customOptions,
        params: args?.params ?? { hh_data: true },
      }),
      providesTags: ['postHhUser', 'patchHhUser', 'deleteHhUser'],
    }),

    deleteHhUser: build.mutation<
      HhUser,
      { id: number; customOptions?: RequestOptionsType }
    >({
      query: ({ id, customOptions }) => ({
        url: getHhUserURL(`/${id}/`),
        method: 'delete',
        customOptions,
      }),
      invalidatesTags: ['deleteHhUser'],
    }),

    patchUser: build.mutation<
      HhUser,
      {
        data: { cookies: string };
        hhUserId: string;
        customOptions?: RequestOptionsType;
      }
    >({
      query: ({ data, hhUserId, customOptions }) => ({
        url: getHhUserURL(`/${hhUserId}/`),
        method: 'patch',
        data,
        customOptions,
      }),
      invalidatesTags: ['patchHhUser'],
    }),
    postHhUser: build.mutation<
      HhUser,
      {
        data: HhUserRequest;
        customOptions?: RequestOptionsType;
      }
    >({
      query: ({ data, customOptions }) => ({
        url: getHhUserURL(),
        method: 'post',
        data,
        customOptions,
      }),
      invalidatesTags: ['postHhUser'],
    }),
  }),
});

export const { useGetHhUserQuery } = hhUserApi;
