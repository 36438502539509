import { Button, ButtonType } from 'Components/UI-kit/Buttons/Button';

import {
  BrowserEnum,
  getBrowserAndVersion,
} from 'Shared/Utils/getBrowserAndVersion';
import { Option } from '../Option/Option';
import { TextInput } from 'Components/UI-kit/TextInput/TextInput';
import {
  MenuPlacement,
  OnHandleOptionType,
  SelectOption,
  SelectorValue,
} from '../types';
import { Ref } from 'react';
import { Flatten } from 'Shared/interfaces';
import styles from './ListBox.module.scss';

export type ListBoxProps = {
  areOptionsShown: boolean;
  listboxRef: Ref<HTMLDivElement>;
  menuPlacement: MenuPlacement;
  selectorId: string;
  activeIndex: number;
  value: SelectorValue;
  onHandleOption: OnHandleOptionType;
  setActiveIndex: React.Dispatch<React.SetStateAction<number>>;
  options: SelectOption[];
  inputValue: string;
  setInputValue: React.Dispatch<React.SetStateAction<string>>;
  onCreateOption?: () => void;
  onHandlePastToClipboard?: () => void;
  onDeleteOption?: (value: Flatten<SelectorValue>) => void;
  searchValue: string;
  onChangeSearchValue: (inputValue: string) => void;
};
export const ListBox = ({
  areOptionsShown,
  listboxRef,
  menuPlacement,
  selectorId,
  activeIndex,
  value,
  onHandleOption,
  options,
  onCreateOption = undefined,
  inputValue,
  setInputValue,
  onHandlePastToClipboard,
  onDeleteOption,
  searchValue,
}: ListBoxProps) => {
  const renderOptions = () => {
    if (!options.length) return <Option>Нет опций</Option>;
    // фильтруем опции по вхождению в searchValue
    const filteredOptionsbySearchInput = options.filter((option) =>
      option.label.toLowerCase().includes(searchValue.toLowerCase()),
    );

    const prepareOptions = filteredOptionsbySearchInput.reduce(
      (acc: JSX.Element[], option, index) => {
        if (value && Array.isArray(value)) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          if (value.includes(option.value)) return acc;
        }

        const onClick = () => {
          onHandleOption({ selectValue: option.value, index });
        };

        const onHandleDelete = onDeleteOption
          ? () => {
              onDeleteOption(option.value);
            }
          : undefined;

        return [
          ...acc,
          <Option
            onHandleDelete={onHandleDelete}
            onClick={onClick}
            key={`${option.value} ${index === activeIndex}`}
            renderIcon={option.renderIcon}
            isAllOption={option.isAllOption}
            isSelect={option.value === value}
            isActive={index === activeIndex}
            id={selectorId + index}
          >
            {option.label}
          </Option>,
        ];
      },
      [],
    );

    if (!prepareOptions.length) {
      return <Option>Нет опций</Option>;
    }
    return prepareOptions;
  };

  return (
    <div
      ref={listboxRef}
      className={[
        styles[menuPlacement],
        areOptionsShown ? '' : 'visually-hidden',
      ].join(' ')}
      id={selectorId + '-listbox'}
      role='listbox'
      aria-expanded={areOptionsShown}
      aria-labelledby={selectorId + '-label'}
    >
      {renderOptions()}
      {onCreateOption && (
        <div key={'inputWithButton'} className={'flex gap-3 relative'}>
          <TextInput
            autoComplete={'none'}
            placeholder={'Добавить ссылку на компанию'}
            onChange={({ currentTarget: { value } }) => setInputValue(value)}
            value={inputValue}
          />
          {!inputValue.trim() &&
          getBrowserAndVersion().browser !== BrowserEnum.Firefox ? (
            <Button
              onClick={onHandlePastToClipboard}
              className={'absolute right-2 top-1/2 -translate-y-1/2'}
              interfaceType={ButtonType.Small}
            >
              Вставить
            </Button>
          ) : (
            <Button
              onClick={onCreateOption}
              className={'absolute right-2 top-1/2 -translate-y-1/2'}
              interfaceType={ButtonType.Small}
            >
              Добавить
            </Button>
          )}
        </div>
      )}
    </div>
  );
};
