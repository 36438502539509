// Generated by ts-to-zod
import { z } from 'zod';

import { CreateMailingRequestSchemaGen } from './gen/CreateMailingRequestSchemaGen';

const CreateMailingRequestSchema = z.intersection(
  z
    .object({
      resume_hash: z.string({ message: 'Поле обязательно' }),
      search_key: z
        .string({ message: 'Поле обязательно' })
        .trim()
        .min(1, { message: 'Не менее 1 символа' }),
      hh_user: z.number({ message: 'Поле обязательно' }),
      plan: z
        .number({ message: 'Только цифры' })
        .min(1, { message: 'Не менее 1' })
        .max(100000, { message: 'Не более 100000' })
        .optional(),
      daily_plan: z
        .number({ message: 'Только цифры' })
        .min(1, { message: 'Не менее 1' })
        .max(200, { message: 'Не более 200' })
        .optional(),
    })
    .refine(
      (data) => {
        // Проверяем только если оба значения определены
        if (data.plan !== undefined && data.daily_plan !== undefined) {
          return data.plan >= data.daily_plan;
        }
        return true;
      },
      {
        message:
          'Суммарное количество откликов не должно быть меньше ежедневного количества откликов',
        path: ['plan'],
      },
    ),
  CreateMailingRequestSchemaGen,
);
export { CreateMailingRequestSchema };
