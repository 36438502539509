import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useTypedDispatch } from '../../../hooks/useTypedDispatch';
import { authThunk } from '../../../redux/slices/authSlice';
import { useLoginMutation } from '../../../services/RTKService/auth/endpoints/authApi';
import { addServerErrors } from '../../../Shared/hookForm/addServerErrors';
import {
  validEmailRules,
  validPasswordRules,
} from '../../../Shared/hookForm/validationRules';
import { setUserInLocalStorage } from '../../../Shared/Utils/localStorage/setUserInLocalStorage';
import { Button, ButtonType } from '../Buttons/Button';
import { HookFormTextInput } from '../TextInput/HookFormTextInput';
import styles from './SignInCard.module.scss';
import { Login } from '../../../Entities';
import { NavigationPath } from '../../../config/navigation.data';
import { ErrorsMapper } from '../ServerErrors/ErrorsMapper';

interface ISignInForm {
  email: string;
  password: string;
}

interface Props {
  onClickSignUp: () => void;
}

export const SignInCard = ({ onClickSignUp }: Props) => {
  const dispatch = useTypedDispatch();

  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<ISignInForm>();

  const [login] = useLoginMutation();

  const onSubmit = handleSubmit(async (payload) => {
    const res = await login({
      data: payload,
      customOptions: {
        showErrorToast: false,
        notToken: true,
      },
    });
    if ('data' in res) {
      await setUserInLocalStorage(res.data);
      await dispatch(authThunk.login(res.data));
      navigate(NavigationPath.mailing);
      return;
    }
    if ('error' in res) {
      addServerErrors(res, setError);
    }
  });

  return (
    <div className={styles.SignInCard}>
      <h2>Авторизация</h2>
      <div className={styles.inputsHolder}>
        <HookFormTextInput<ISignInForm>
          control={control}
          name={'email'}
          label={'Почта'}
          isHiddenLabel
          placeholder={'Почта'}
          rules={validEmailRules}
          autoComplete={'email'}
          isShowError={false}
        />
        <HookFormTextInput<ISignInForm>
          control={control}
          name={'password'}
          label={'Пароль'}
          isHiddenLabel
          placeholder={'Пароль'}
          rules={validPasswordRules}
          autoComplete={'password'}
          type={'password'}
          isShowError={false}
        />
      </div>

      <ErrorsMapper<Login>
        errors={errors}
        allErrors
        renderFunc={(mappedErrors) => (
          <div className={styles.errorContainer}>
            {mappedErrors.map((err, index) => (
              <span key={index} className={styles.errorMessage}>
                {err}
              </span>
            ))}
          </div>
        )}
      />
      <div className={styles.buttonsHolder}>
        <Button onClick={onSubmit} interfaceType={ButtonType.Primary}>
          Войти
        </Button>
        <Button onClick={onClickSignUp} interfaceType={ButtonType.Secondary}>
          Регистрация
        </Button>
      </div>
    </div>
  );
};
